var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"addManualInputToInvoice",attrs:{"title":_vm.$t('invoices.adding_article'),"size":"lg"},on:{"ok":_vm.validate},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":_vm.hide}},[_vm._v(" "+_vm._s(_vm.$t('general.cancel'))+" ")]),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.manualInputItem.vat && _vm.$numberRounding(_vm.manualInputItem.total_price * 1000000) > _vm.$numberRounding(_vm.taxesMap.get(_vm.manualInputItem.vat.vat))},on:{"click":_vm.validate}},[(!_vm.editing)?_c('span',[_vm._v(_vm._s(_vm.$t('general.add')))]):_c('span',[_vm._v(_vm._s(_vm.$t('general.edit')))])])]},proxy:true}])},[_c('validation-observer',{ref:"validationAddItem"},[_c('div',[_c('b-form-group',{attrs:{"label":_vm.$t('invoices.article')}},[_c('validation-provider',{attrs:{"name":_vm.$t('invoices.article'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"ingredient",attrs:{"taggable":"","options":_vm.products,"label":"name"},model:{value:(_vm.selectedProduct),callback:function ($$v) {_vm.selectedProduct=$$v},expression:"selectedProduct"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-3"},[_c('b-form-group',{attrs:{"label":_vm.$t('invoices.price_per_unit')}},[_c('validation-provider',{attrs:{"name":_vm.$t('invoices.price'),"rules":"required|min_number: 0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"€"}},[_c('b-form-input',{attrs:{"type":"number"},on:{"input":_vm.calculatePriceAndVat},model:{value:(_vm.manualInputItem.price_per_item),callback:function ($$v) {_vm.$set(_vm.manualInputItem, "price_per_item", _vm._n($$v))},expression:"manualInputItem.price_per_item"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-12 col-md-3"},[_c('b-form-group',{attrs:{"label":_vm.$t('form_data.quantity')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form_data.quantity'),"rules":"required|min_number: 0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number"},on:{"input":_vm.calculatePriceAndVat},model:{value:(_vm.manualInputItem.quantity),callback:function ($$v) {_vm.$set(_vm.manualInputItem, "quantity", _vm._n($$v))},expression:"manualInputItem.quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-12 col-md-3"},[_c('b-form-group',{attrs:{"label":_vm.$t('keys.vat.vat')}},[_c('validation-provider',{attrs:{"name":_vm.$t('keys.vat.vat'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"appendToBody":"","options":_vm.invoice.taxes,"label":"vat"},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var vat = ref.vat;
return [_vm._v(" "+_vm._s(vat/100)+"% ")]}},{key:"option",fn:function(ref){
var vat = ref.vat;
return [_vm._v(" "+_vm._s(vat/100)+"% ")]}}],null,true),model:{value:(_vm.manualInputItem.vat),callback:function ($$v) {_vm.$set(_vm.manualInputItem, "vat", $$v)},expression:"manualInputItem.vat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-12 col-md-3"},[_c('b-form-group',{attrs:{"label":_vm.$t('general.discount')}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.discount')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"%"}},[_c('b-form-input',{attrs:{"type":"number"},on:{"input":_vm.calculatePriceAndVat},model:{value:(_vm.manualInputItem.discount),callback:function ($$v) {_vm.$set(_vm.manualInputItem, "discount", _vm._n($$v))},expression:"manualInputItem.discount"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-12 col-md-12"},[_c('b-form-group',{attrs:{"label":_vm.$t('config.printer_configs.total_price')}},[_c('b-input-group',{attrs:{"append":"€"}},[_c('b-form-input',{attrs:{"disabled":true},model:{value:(_vm.manualInputItem.total_price),callback:function ($$v) {_vm.$set(_vm.manualInputItem, "total_price", $$v)},expression:"manualInputItem.total_price"}})],1)],1)],1),_c('div',{staticClass:"px-1"},[(_vm.manualInputItem && _vm.manualInputItem.vat && _vm.manualInputItem.total_price && (_vm.$numberRounding(_vm.taxesMap.get(_vm.manualInputItem.vat.vat)) - _vm.manualInputItem.total_price) > 0)?_c('b-alert',{staticClass:"p-1",attrs:{"show":"","variant":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('invoices.missing_text_1'))+" "+_vm._s(Number(_vm.$numberRounding(_vm.taxesMap.get(_vm.manualInputItem.vat.vat)) - _vm.manualInputItem.total_price).toFixed(2))+"€ "+_vm._s(_vm.$t('invoices.missing_text_2'))+". ")]):(_vm.manualInputItem && _vm.manualInputItem.vat && _vm.manualInputItem.total_price && (_vm.$numberRounding(_vm.taxesMap.get(_vm.manualInputItem.vat.vat)) - _vm.manualInputItem.total_price) === 0)?_c('b-alert',{staticClass:"p-1",attrs:{"show":"","variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('invoices.used_amount_text'))+". ")]):_c('b-alert',{staticClass:"p-1",attrs:{"show":"","variant":"danger"}},[_vm._v(" "+_vm._s(_vm.$t('invoices.exceeding_amount'))+". ")])],1)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }