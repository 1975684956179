<template>
    <b-modal :title="$t('invoices.join_by_vat')" v-model="modalActive">
        <b-form-group :label="$t('invoices.choose_naming')">
            <v-select appendToBody taggable v-model="name" :options="options" label="name" @option:created="createNewNamingOption"></v-select>
        </b-form-group>
        <template #modal-footer>
            <div class="pr-2">
                <b-button @click="cancel">
                    {{cancelButton}}
                </b-button>
            </div>
            <div>
                <b-button :disabled="!name" @click="chosenName" variant="primary">
                    {{confirmButton}}
                </b-button>
            </div>
        </template>
    </b-modal>
</template>
<script>
    import { BModal, BFormGroup, BButton } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import * as Sentry from '@sentry/vue'
    import i18n from '@/i18n/i18n'

    export default {
        components: {
            BModal,
            vSelect,
            BFormGroup,
            BButton
        },
        props: {
            confirmButton: {
                type: String,
                default: `${i18n.t('general.accept')}`
            },
            cancelButton: {
                type: String,
                default: `${i18n.t('general.cancel')}`
            }
        },
        data() {
            return {
                modalActive: false,
                options: [],
                name: []
            }
        },
        methods: {
            async open() {
                this.modalActive = true
    
                try {
                    const response = await this.$http.get('/api/client/v1/invoices/naming_options')
                    this.options = response.data ?? []
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            async createNewNamingOption(param) {
                try {
                    await this.$http.post('/api/client/v1/invoices/naming_options', param)
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            chosenName() {
                const payload = {
                    name: this.name.name
                }
        
                this.$emit('chosenName', payload)
                this.modalActive = false
            },
            cancel() {
                this.modalActive = false
            }
        }
    }
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>
