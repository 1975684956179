<template>
    <b-overlay :show="showLoader">
        <b-card :title="$t('invoices.issuing_invoice')">
            <second-step-invoice v-if="invoice" :invoice="invoice"></second-step-invoice>
        </b-card>
    </b-overlay>
</template>

<script>
    import { BCard, BOverlay } from 'bootstrap-vue'
    import SecondStepInvoice from '@/views/Invoices/Issued/components/SecondStepInvoice.vue'
    import * as Sentry from '@sentry/vue'

    export default {
        components:{ SecondStepInvoice, BCard, BOverlay },
        data() {
            return {
                showLoader: false,
                invoice_id: '',
                invoice: null
            }
        },
        methods: {
            async loadData() {
                try {
                    this.showLoader = true
    
                    const response = await this.$http.get(`/api/client/v1/invoices/${this.invoice_id}`)
                    this.invoice = response.data ?? {}
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            }
        },
        mounted() {
            this.invoice_id = this.$route.params.invoice_id
            this.loadData()
        }
    }
</script>

<style lang="scss" scoped>
</style>