<template>
    <b-modal ref="modal" :title="title" size="lg" @hide="close">
        <div>
            {{ text }}
        </div>
        
        <template #modal-footer>
            <div class="pr-2">
                <b-button @click="cancel">
                    {{cancelButton}}
                </b-button>
            </div>
            <div>
                <b-button @click="confirm" variant="danger">
                    {{confirmButton}}
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import { BModal, BButton } from 'bootstrap-vue'
    import i18n from '@/i18n/i18n'

    export default {
        components:{
            BModal,
            BButton
        },
        props: {
            text: String,
            title: String,
            showButtons: {
                type: Boolean,
                default: true
            },
            confirmButton: {
                type: String,
                default: `${i18n.t('general.accept')}`
            },
            cancelButton: {
                type: String,
                default: `${i18n.t('general.cancel')}`
            }
        },
        data() {
            return {
                confirmTrue: false
            }
        },
        methods:{
            cancel() {
                this.confirmTrue = false
                this.hide()
            },
            confirm() {
                this.confirmTrue = true
                this.hide()
            },
            close() {
                if (this.confirmTrue) {
                    this.$emit('confirm')
                } else {
                    this.$emit('cancel')
                }
                this.confirmTrue = false
            },
            show() {
                this.$refs.modal.show()
            },
            hide() {
                this.$refs.modal.hide()
            }
        }
    }
</script>

<style scoped>
</style>