<template>
    <b-modal ref="addManualInputToInvoice" :title="$t('invoices.adding_article')" size="lg" @ok="validate">
        <validation-observer ref="validationAddItem">
            <div>
                <b-form-group :label="$t('invoices.article')">
                    <validation-provider #default="{ errors }" :name="$t('invoices.article')" rules="required">
                        <v-select ref="ingredient" taggable v-model="selectedProduct" :options="products" label="name"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                
                <div class="row">
                    <div class="col-12 col-md-3">
                        <b-form-group :label="$t('invoices.price_per_unit')">
                            <validation-provider #default="{ errors }" :name="$t('invoices.price')" rules="required|min_number: 0">
                                <b-input-group append="€">
                                    <b-form-input type="number" v-model.number="manualInputItem.price_per_item" @input="calculatePriceAndVat"></b-form-input>
                                </b-input-group>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>
    
                    
                    <div class="col-12 col-md-3">
                        <b-form-group :label="$t('form_data.quantity')">
                            <validation-provider #default="{ errors }" :name="$t('form_data.quantity')" rules="required|min_number: 0">
                                <b-form-input type="number" v-model.number="manualInputItem.quantity" @input="calculatePriceAndVat"></b-form-input>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>
        
                    <div class="col-12 col-md-3">
                        <b-form-group :label="$t('keys.vat.vat')">
                            <validation-provider #default="{ errors }" :name="$t('keys.vat.vat')" rules="required">
                                <v-select appendToBody v-model="manualInputItem.vat" :options="invoice.taxes" label="vat">
                                    <template #selected-option="{ vat }">
                                        {{vat/100}}%
                                    </template>
                                    <template v-slot:option="{ vat }">
                                        {{vat/100}}%
                                    </template>
                                </v-select>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>
                 
                    <div class="col-12 col-md-3">
                        <b-form-group :label="$t('general.discount')">
                            <validation-provider #default="{ errors }" :name="$t('general.discount')">
                                <b-input-group append="%">
                                    <b-form-input type="number" v-model.number="manualInputItem.discount" @input="calculatePriceAndVat"></b-form-input>
                                </b-input-group>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                
                    </div>
                
                    
                    <div class="col-12 col-md-12">
                        <b-form-group :label="$t('config.printer_configs.total_price')">
                            <b-input-group append="€">
                                <b-form-input v-model="manualInputItem.total_price" :disabled="true"></b-form-input>
                            </b-input-group>
                        </b-form-group>
                    </div>
                    
                    <div class="px-1">
                        <b-alert v-if="manualInputItem && manualInputItem.vat && manualInputItem.total_price && ($numberRounding(taxesMap.get(manualInputItem.vat.vat)) - manualInputItem.total_price) > 0" class="p-1" show variant="warning">
                            {{$t('invoices.missing_text_1')}} {{ Number($numberRounding(taxesMap.get(manualInputItem.vat.vat)) - manualInputItem.total_price).toFixed(2) }}€ {{ $t('invoices.missing_text_2') }}.
                        </b-alert>
                        <b-alert v-else-if="manualInputItem && manualInputItem.vat && manualInputItem.total_price && ($numberRounding(taxesMap.get(manualInputItem.vat.vat)) - manualInputItem.total_price) === 0" class="p-1" show variant="success">
                            {{ $t('invoices.used_amount_text') }}.
                        </b-alert>
                        <b-alert v-else class="p-1" show variant="danger">
                            {{ $t('invoices.exceeding_amount') }}.
                        </b-alert>
                    </div>
                </div>
            </div>
        </validation-observer>
        
        <template #modal-footer>
            <b-button variant="secondary" @click="hide">
                {{ $t('general.cancel') }}
            </b-button>
            <b-button variant="primary" :disabled="manualInputItem.vat && $numberRounding(manualInputItem.total_price * 1000000) > $numberRounding(taxesMap.get(manualInputItem.vat.vat))" @click="validate">
                <span v-if="!editing">{{ $t('general.add') }}</span>
                <span v-else>{{ $t('general.edit') }}</span>
            </b-button>
        </template>
    </b-modal>
</template>


<script>
    import vSelect from 'vue-select'
    import { BModal, BFormGroup, BFormInput, BInputGroup, BButton, BAlert } from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@validations'
    import {minNumber, decimalCustom} from '@core/utils/validations/validations'
    import * as Sentry from '@sentry/vue'

    export default {
        components:{
            vSelect,
            BModal,
            BFormGroup,
            BFormInput,
            BInputGroup,
            BButton,
            ValidationProvider,
            ValidationObserver,
            BAlert
        },
        props: {
            remainingPrice: Number,
            invoice: Object,
            taxesMap: Map
        },
        data() {
            return {
                products: [],
                rawProducts: [],
                selectedProduct: null,
                manualInputItem: {
                    id: '',
                    name: '',
                    quantity: '',
                    price_per_item: '',
                    price_per_item_without_vat: '',
                    discount: 0,
                    total_price: '',
                    total_price_without_vat: '',
                    vat: null
                },
                editing: false,
                index: -1,
                selectedTax: '',
                required,
                minNumber,
                decimalCustom
            }
        },
        methods:{
            validate() {
                this.$refs.validationAddItem.validate().then((success) =>  {
                    if (success) {
                        this.addManualItem()
                    }
                })
            },
            addManualItem() {
                const payload = {
                    id: this.index,
                    name: this.selectedProduct.name,
                    quantity: this.manualInputItem.quantity * 100,
                    price_per_item: this.manualInputItem.price_per_item * 1000000,
                    price_per_item_without_vat: this.manualInputItem.price_per_item * (1 - (this.manualInputItem.vat / 100)) * 1000000,
                    discount: this.manualInputItem.discount * 100,
                    total_price: this.manualInputItem.total_price * 1000000,
                    total_price_without_vat: this.manualInputItem.total_price * (Math.round(((1 - (this.manualInputItem.vat / 100)) + Number.EPSILON) * 100) / 100) * 1000000,
                    vat: this.manualInputItem.vat.vat
                }
                
                this.$emit('addManualItem', payload, this.editing)
                this.hide()
            },
            calculatePriceAndVat() {
                if (this.manualInputItem.quantity && this.manualInputItem.price_per_item) {
                    this.manualInputItem.total_price = this.manualInputItem.price_per_item * this.manualInputItem.quantity * (Math.round(((1 - (this.manualInputItem.discount / 100)) + Number.EPSILON) * 100) / 100)
                }
            },
            async open(data, index) {
                if (!data) {
                    this.editing = false
                    this.selectedProduct = null
                    this.manualInputItem = {
                        name: '',
                        quantity: '',
                        price_per_item: '',
                        price_per_item_without_vat: '',
                        discount: 0,
                        total_price: '',
                        total_price_without_vat: '',
                        vat: null
                    }
                } else {
                    this.editing = true
                    this.index = index
                    const editItem = JSON.parse(JSON.stringify(data))
                    this.calculateItem(editItem)
                }
            
                this.$refs.addManualInputToInvoice.show()
                if (!this.editing) {
                    await this.$nextTick()
                    this.$refs.ingredient.open = true
                    await this.$nextTick()
                    this.$refs.ingredient.$refs.search.focus()
                }
            },
            hide() {
                this.$refs.addManualInputToInvoice.hide()
            },
            async loadData() {
                try {
                    const resp = await this.$http.get('/api/client/v1/products/')
                    this.rawProducts = resp.data
                    this.products = this.rawProducts.filter(ingredient => ingredient.disabled !== true)
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                }
            },
            calculateItem(data) {
                this.manualInputItem = {
                    name: data.name,
                    quantity: data.quantity / 100,
                    price_per_item: data.price_per_item / 1000000,
                    discount: data.discount / 100,
                    total_price: data.total_price / 1000000,
                    vat: data.vat / 100
                }
            
                this.selectedProduct = this.rawProducts.find(ele => ele.name === data.name)
                this.calculatePriceAndVat()
            }
        },
        mounted() {
            this.loadData()
            
        }
    }
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>